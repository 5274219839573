import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/sensitive/">Sensitive</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-heritage/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-heritage/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestra-historia/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/a_nossa_historia/">PT</a>
                      </li>
                      <li>
                        <a href="/it/our-heritage/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-heritage/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="heritage-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/our_heritage_1680x405_topLarge.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/our_heritage_960x405_topSmalla.jpg"
                  />
                </div>
              </div>
            </section>
            <div className="page-heritage__intro">
              <h1>Ο ΕΙΔΙΚΟΣ ΣΤΗΝ ΑΝΤΗΛΙΑΚΗ ΠΕΡΙΠΟΙΗΣΗ ΓΕΝΝΙΕΤΑΙ</h1>
              <p> </p>
              <p>
                Εδώ και 70 χρόνια, το PIZ BUIN<sup>®</sup> προσφέρει σε όλους
                τους λάτρεις του ήλιου λύσεις αντηλιακής περιποίησης που τους
                βοηθούν να επιτύχουν την τέλεια ισορροπία μεταξύ ενός υπέροχου
                μαυρίσματος και της προστασίας που χρειάζονται. Στην
                πραγματικότητα, το PIZ BUIN<sup>®</sup> ήταν μία από τις πρώτες
                μάρκες που κατέστησε το μαύρισμα πιο ασφαλές, εφαρμόζοντας το
                σύστημα του δείκτη αντηλιακής προστασίας (SPF) τη δεκαετία του
                1960. Επομένως, οπουδήποτε υπάρχει ήλιος στην παραλία, στο βουνό
                ή στην πόλη, το PIZ BUIN<sup>®</sup> είναι πάντα εκεί για να
                απολαμβάνετε τη ζωή κάτω από τον ήλιο με ασφάλεια.
              </p>
              <p>
                Μετά την ταλαιπωρία του από την υπερβολική έκθεση στον ήλιο κατά
                την αναρρίχηση της κορυφής των Άλπεων Piz Buin το 1938, ο
                φοιτητής της σχολής χημικών, Franz Greiter, ανέπτυξε το 1946 ένα
                από τα πρώτα προϊόντα αντηλιακής προστασίας στον κόσμο, το PIZ
                BUIN<sup>®</sup> “Gletscher Creme” ή Glacier Cream. Σε
                συνεργασία με τη σύζυγό του, Marga, μια εκπαιδευμένη αισθητικό,
                ανέπτυξαν ένα ειδικό στην αντηλιακή περιποίηση προϊόν, ποu
                προσέφερε πρωτοποριακή αντηλιακή προστασία με καλλυντική
                περιποίηση του δέρματος σε όλους όσους ήθελαν να απολαμβάνουν τη
                ζωή κάτω από τον ήλιο.{" "}
              </p>
            </div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/InstantGlow_Heritage.jpg" />
                  </figure>
                  <h2>2017</h2>
                  <p></p>
                  <p>
                    <strong>Instant Glow</strong>
                    <br />
                    Λανσάρισμα του νέου PIZ BUIN Instant Glow. <br />
                    το νέο αντιηλιακό που χαρίζει άμεση λάμψη <br />
                    <br />
                    <br />
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/Protect_and_Cool_Frozen_640_02.jpg" />
                  </figure>
                  <h2>2016</h2>
                  <p></p>
                  <p>
                    <strong>
                      Protect &amp; Cool
                      <br />
                    </strong>
                    Λανσάρισμα του νέου PIZ BUIN Protect &amp; Cool.
                    <br />
                    Αντηλιακός αφρός αναζωογόνησης με ευχάριστη αίσθηση δροσιάς.
                    <strong>
                      <br />
                    </strong>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/70_Years_Celebration_640_021.jpg" />
                  </figure>
                  <h2>2016</h2>
                  <p></p>
                  <p>
                    Η PIZ BUIN® ταξιδεύει στην Κόστα Ρίκα μαζί με τους Sun
                    Ambassadors της για να ανακαλύψει και να μοιραστεί τις
                    εμπειρίες τους κάτω από τον ήλιο.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/2015-KV-Instant-Glow-Vertical.jpg" />
                  </figure>
                  <h2>2015</h2>
                  <p></p>
                  <p>
                    Λανσάρισμα του νέου PIZ BUIN INSTANT GLOW<sup>®</sup> Skin
                    Illuminating Sun Spray. Χαρίζει άμεση προστασία &amp;
                    ομορφιά για όλο το καλοκαίρι.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2014b.jpg" />
                  </figure>
                  <h2>2014</h2>
                  <p></p>
                  <p>
                    Τα γυρίσματα για την καμπάνια του PIZ BUIN WET SKIN
                    <sup>®</sup> Transparent Sun Spray 2014 έγιναν στην
                    καλοκαίρι.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/2019-allergy-mobile.jpg" />
                  </figure>
                  <h2>2014</h2>
                  <p></p>
                  <p>
                    Λανσάρισμα PIZ BUIN<sup>®</sup> ULTRA LIGHT Dry Touch Face.
                    Αποτελεσματική προστασία που στεγνώνει αμέσως και είναι
                    εξαιρετικά λεπτόρρευστη.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2013b.jpg" />
                  </figure>
                  <h2>2013</h2>
                  <p></p>
                  <p>
                    Το PIZ BUIN<sup>®</sup> ξεκινά την καλοκαιρινή εκστρατεία
                    για το νέο 2013 WET SKIN Transparent Sun Spray στις
                    Σεϊχέλες.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2013.jpg" />
                  </figure>
                  <h2>2013</h2>
                  <p></p>
                  <p>
                    Λανσάρισμα του PIZ BUIN WET SKIN<sup>®</sup>, ένα από τα
                    πρώτα αντηλιακά που εφαρμόζονται απευθείας σε υγρό δέρμα.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2012.jpg" />
                  </figure>
                  <h2>2012</h2>
                  <p></p>
                  <p>
                    Λανσάρισμα του PIZ BUIN TAN &amp; PROTECT<sup>®</sup>. Για
                    ένα γρηγορότερο*, πιο όμορφο μαύρισμα ... με ασφάλεια.
                    <br />
                    <span className="footNote">
                      *Περιέχει τεχνολογία ενίσχυσης του φυσικού μαυρίσματος{" "}
                    </span>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2011.jpg" />
                  </figure>
                  <h2>2011</h2>
                  <p></p>
                  <p>
                    65ή επέτειος και προώθηση του ΝΕΟΥ Glacier Cream. Η
                    τελευταία λέξη στην τεχνολογία αντηλιακής προστασίας για τα
                    προϊόντα PIZ BUIN<sup>®</sup> in sun.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2010.jpg" />
                  </figure>
                  <h2>2010</h2>
                  <p></p>
                  <p>
                    PIZ BUIN<sup>®</sup> MOUNTAIN, σύμπλεγμα ασπίδας προστασίας
                    κατά του κρύου (Cold Shield Complex) και εκχύλισμα
                    Edelweiss. PIZ BUIN<sup>®</sup> MOUNTAIN. Ειδική* προστασία
                    από τον ήλιο σε μεγάλο υψόμετρο, σε συνθήκες κρύου και αέρα.
                    <br />
                    <span className="footNote">
                      *Σχεδιασμένο να προστατεύει από τον ήλιο, το κρύο και τον
                      αέρα.
                    </span>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2009.jpg" />
                  </figure>
                  <h2>2009</h2>
                  <p></p>
                  <p>
                    Το προϊόν PIZ BUIN<sup>®</sup> ALLERGY τώρα με το συστατικό
                    Calmanelle<sup>™</sup>, ένα μοναδικό σύμπλεγμα προστασίας
                    που έχει αποδειχθεί ότι βοηθά στην ενίσχυση της
                    ανθεκτικότητας του δέρματός σας*.
                    <br />
                    <span className="footNote">*Δοκιμή in vitro</span>
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2008.jpg" />
                  </figure>
                  <h2>2008</h2>
                  <p></p>
                  <p>
                    Η αντηλιακή σειρά της PIZ BUIN<sup>®</sup> αναβαθμίζεται στη
                    νέα επαναστατική τεχνολογία αντηλιακής προστασίας Helioplex
                    <sup>™</sup>.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2007.jpg" />
                  </figure>
                  <h2>2007</h2>
                  <p></p>
                  <p>
                    Λανσάρισμα του Tan Intensifier – μια σειρά προϊόντων με
                    πρωτοποριακή σύνθεση που διευκολύνει το γρήγορο, φυσικό
                    μαύρισμα με ασυμβίβαστη προστασία.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_2000.jpg" />
                  </figure>
                  <h2>2000</h2>
                  <p></p>
                  <p>
                    Το βαθύ μαύρισμα δίνει τη θέση του στην ηλιοκαμένη λάμψη.{" "}
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_1993.jpg" />
                  </figure>
                  <h2>1993</h2>
                  <p></p>
                  <p>
                    Το πρώτο αντηλιακό PIZ BUIN<sup>®</sup> με τεχνολογία
                    τριπλής προστασίας και βιταμίνη E.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_1984.jpg" />
                  </figure>
                  <h2>1984</h2>
                  <p></p>
                  <p>
                    Οι αναπαραστάσεις της «θεάς του ήλιου» ενισχύουν το γόητρο
                    της μάρκας PIZ BUIN<sup>®</sup>.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_1974.jpg" />
                  </figure>
                  <h2>1974</h2>
                  <p></p>
                  <p>
                    Το πρώτο που παρουσιάζει προϊόντα αντηλιακής προστασίας με
                    αντοχή στο νερό, με αδιάβροχη σύνθεση.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_1962b.jpg" />
                  </figure>
                  <h2>1962</h2>
                  <p></p>
                  <p>Διεξαγωγή δοκιμών στις ελβετοαυστριακές Άλπεις.</p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_1962a.jpg" />
                  </figure>
                  <h2>1962</h2>
                  <p></p>
                  <p>
                    Ένα από τα πρώτα που εφαρμόζουν το δείκτη αντηλιακής
                    προστασίας (SPF).
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_1946.jpg" />
                  </figure>
                  <h2>1946</h2>
                  <p></p>
                  <p>
                    Το PIZ BUIN<sup>®</sup> προωθεί το πρώτο αντηλιακό προϊόν.
                  </p>
                  <p />
                </div>
                <span className="vline" />
                <div className="yearBlock video">
                  <figure>
                    <img src="/assets/images/heritage_img_640_1938.jpg" />
                  </figure>
                  <h2>1938</h2>
                  <p></p>
                  <p>Ο Dr. Franz Greiter αναρριχάται στο βουνό Piz.</p>
                  <p />
                </div>
                <span className="vline" />
              </div>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
       
      </div>
    );
  }
}

export default Page;
